import { ApiService } from 'core/services'
import { Config } from 'core'
import { SignUpModel } from './types'

class AuthService {
  public static instance: AuthService

  constructor() {
    if (AuthService.instance) {
      return AuthService.instance
    }
    AuthService.instance = this
  }
  login(email: string, pass: string) {
    return ApiService.post('auth/login', { email, pass })
  }

  register(user: SignUpModel) {
    return ApiService.post('customers/externalRegister', user)
  }

  recoberPass(email: string) {
    return ApiService.post('auth/recoverPass', { email }, { url: Config.authUrl })
  }

  restorePass(email: string, recoverCode: string, recoverToken: string, newPassword: string) {
    return ApiService.post('auth/restorePass', { email, recoverCode, recoverToken, newPassword })
  }
}

const instance = new AuthService()

Object.freeze(instance)

export const auth = instance
