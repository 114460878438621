import React, { Component } from 'react'
import Formsy from 'formsy-react'
import { InputFormsy, DatepickerFormsy, RadioGroupFormsy, CheckboxFormsy } from 'shared/components/formsy'
import ReCAPTCHA from 'react-google-recaptcha'
import moment from 'moment'
import headImg from 'img/usuario.svg'
import { Container, Row, Col, Alert, ModalHeader, ModalBody, Modal } from 'reactstrap'
import { auth } from 'services/authservice'
import { Layout } from 'core/components'
import { Go, ABanner, AAnimate, ARadio } from 'shared/components/common'
import { scrollTop, PASSWORD, setupPixel, FacebookPixel } from 'core/utils'
import { ApiErrors } from 'core/services/ApiService/types'
import { Config } from 'core'
import AllAbordoLogo from 'img/AllAbordoLogo.svg'

interface RegisterProps {
  pageContext: {
    locale: string
  }
}
interface ISregister {
  canSubmit: boolean
  submitting?: boolean
  status: string | null
  message: string
  mounted: boolean
  recaptcha: boolean
  validForm?: boolean
  collapse: boolean
}

let ReactPixel: FacebookPixel
const minDate = moment().subtract(130, 'years')
const maxDate = moment().subtract(3, 'years')
const initialDate = moment().subtract(25, 'years')

class Register extends Component<RegisterProps, ISregister> {
  form: any
  topRef = React.createRef<HTMLDivElement>()
  state: ISregister = {
    canSubmit: false,
    status: null,
    message: '',
    mounted: false,
    recaptcha: false,
    collapse: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true, collapse: false })
    }, 0)
    ReactPixel = setupPixel()
    ReactPixel?.pageView()
  }
  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }))
  }

  onSubmit = (form: any) => {
    const model = { ...form }
    delete model.repeated_password
    delete model.checkbox
    model.birthday = model.birthday.format('YYYY-MM-DD')
    this.setState({ submitting: true, status: null, message: '' })
    const newState: any = { submitting: false }
    auth
      .register(model)
      .then(() => {
        this.setState({ ...newState, message: 'Usuario registrado con éxito:', status: 'success', canSubmit: false })
        ReactPixel?.track('CompleteRegistration', {
          email: model.email,
          phone: model.phone,
          first_name: model.first_name,
          last_name: model.last_name
        })
        this.toggle()
        this.resetForm()
        this.form.reset()
      })
      .catch(err => {
        if (err.status && err.status === ApiErrors.HANDLED_ERROR) {
          newState.message = err.message
        } else if (err.error) {
          function errorMsg(error: string) {
            if (
              error === 'Email already in use' ||
              error === 'Customer has been deleted' ||
              error === 'Customer has been verified' ||
              error === 'Customer already has a user'
            ) {
              return 'El correo ingresado ya se encuentra registrado.'
            }
          }
          newState.message = errorMsg(err.error.devMessage)
        } else {
          newState.message = 'Error al registrarse. Intente de nuevo'
        }
        this.setState({ ...newState, status: 'error' })
        this.resetForm()
      })
  }

  resetForm = () => {
    const { current } = this.topRef
    if (current) {
      scrollTop(current.offsetTop, 'smooth')
    }
  }

  disableButton = () => {
    this.setState({ validForm: false, canSubmit: false })
  }

  enableButton = () => {
    this.setState(state => ({ validForm: true, canSubmit: state.recaptcha }))
  }
  handleRecaptcha = (token: string | null) => {
    const recap = !!token
    this.setState(state => ({ recaptcha: recap, canSubmit: Boolean(recap && state.validForm) }))
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { canSubmit, status, message, submitting, mounted } = this.state

    return (
      <Layout locale={locale}>
        <Modal isOpen={this.state.collapse} size="lg" toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            <Row>
              <Col />
              <Col />
              <Col />
              <Col />
              <Col />
              <Col />
              <Col />
              <Col />
              <Col>
                <img style={{ width: '200px' }} src={AllAbordoLogo} className="img-responsive" />
              </Col>
              <Col />
            </Row>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <h3>
                  <img src="https://img.icons8.com/color/48/000000/ok--v1.png" />
                  Usuario registrado con éxito!{' '}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>
                  <img src="https://img.icons8.com/color/48/000000/important-mail.png" />
                  Para iniciar sesión por primera vez confirme su cuenta de correo.{' '}
                </h5>
              </Col>
            </Row>

            <Row>
              <Col>
                <span className="confirmacion">
                  NOTA: En su cuenta de correo encontrara un email con las instrucciones de confirmación. En caso de que no se encuentre en
                  la bandeja principal, busque en la bandeja spam u otros.
                </span>
              </Col>
            </Row>
            <Row>
              <Col />
              <Col>
                <button
                  type="submit"
                  onClick={() => {
                    this.toggle()
                  }}
                  className="m-btn-med-rosa "
                >
                  <Go to="/iniciarsesion" className="modalRegistrar">
                    Iniciar sesión aquí
                  </Go>
                </button>
              </Col>
              <Col />
            </Row>
          </ModalBody>
        </Modal>

        <AAnimate type="fadeIn" in={mounted}>
          <ABanner title={'Crear una cuenta'} titleClassName="big-white-op" content={<img className="ml-auto" src={headImg} />} />
          <Container>
            <div ref={this.topRef} className="login">
              <Formsy
                ref={(node: any) => {
                  this.form = node
                }}
                className="register-container"
                onValidSubmit={this.onSubmit}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
              >
                <Container>
                  {status ? (
                    <Alert color={status === 'success' ? 'success' : 'danger'} className="d-inline-block">
                      {message} {status === 'success' ? <Go to="/iniciarsesion">Iniciar sesión aquí</Go> : null}
                    </Alert>
                  ) : null}
                  <Row>
                    <Col sm={6} xs={12}>
                      <InputFormsy
                        type="text"
                        name="first_name"
                        label="Nombre(s)"
                        inputProps={{ placeholder: 'Escribe tus nombres', disabled: submitting }}
                        validations={{ isSpecialWords: true }}
                        validationErrors={{ isSpecialWords: 'Este campo solo acepta letras' }}
                        required
                      />
                    </Col>
                    <Col sm={6} xs={12}>
                      <InputFormsy
                        type="text"
                        name="last_name"
                        label="Apellidos(s)"
                        inputProps={{ placeholder: 'Escribe tus apellidos', disabled: submitting }}
                        validations={{ isSpecialWords: true }}
                        validationErrors={{ isSpecialWords: 'Este campo solo acepta letras' }}
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} xs={12}>
                      <InputFormsy
                        type="text"
                        name="phone"
                        label="Teléfono"
                        inputProps={{ placeholder: 'Escribe tu teléfono', disabled: submitting, maxLength: 10 }}
                        required
                        validations={{
                          isNumeric: true,
                          minLength: 10
                        }}
                        validationErrors={{
                          isNumeric: 'Formato de número no valido',
                          minLength: 'Longitud no valida'
                        }}
                      />
                    </Col>
                    <Col sm={6} xs={12}>
                      <DatepickerFormsy
                        name="birthday"
                        label="Fecha de nacimiento"
                        value={initialDate}
                        pickerProps={{
                          maxDate,
                          minDate,
                          dropdownMode: 'select',
                          showYearDropdown: true,
                          showMonthDropdown: true,
                          disabled: submitting,
                          className: 'custom-select3',
                          placeholderText: 'AAAA/MM/DD'
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <InputFormsy
                        type="text"
                        name="email"
                        label="Correo electronico"
                        inputProps={{
                          placeholder: 'Escribe tu correo electronico',
                          type: 'email',
                          disabled: submitting
                        }}
                        required
                        validations={{
                          isEmail: true
                        }}
                        validationErrors={{
                          isEmail: 'Formato no valido'
                        }}
                      />
                    </Col>
                    <Col sm={6}>
                      <RadioGroupFormsy name="gender" label="Género" value="m" radioProps={{ inline: true, disabled: submitting }}>
                        <ARadio label="Masculino" value={'m'} />
                        <ARadio label="Femenino" value={'f'} />
                      </RadioGroupFormsy>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <InputFormsy
                        name="pass"
                        label="Contraseña"
                        inputProps={{ disabled: submitting, placeholder: 'Escribe tu nueva Contraseña', type: 'password' }}
                        validations={{
                          matchRegexp: PASSWORD
                        }}
                        validationErrors={{
                          matchRegexp:
                            'La contraseña introducida no es segura. Debe tener al menos un número y una longitud de 8 caracteres'
                        }}
                        required
                      />
                    </Col>
                    <Col sm={6}>
                      <InputFormsy
                        type="password"
                        name="repeated_password"
                        label="Confirmar contraseña"
                        inputProps={{ disabled: submitting, placeholder: 'Escribe de nuevo la Contraseña', type: 'password' }}
                        required
                        validations={{
                          equalsField: 'pass',
                          matchRegexp: PASSWORD
                        }}
                        validationErrors={{
                          equalsField: 'Las contraseñas no coinciden',
                          matchRegexp:
                            'La contraseña introducida no es segura. Debe tener al menos un número y una longitud de 8 caracteres'
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} xs={12}>
                      <ReCAPTCHA sitekey={Config.recaptcha} onChange={this.handleRecaptcha} />
                    </Col>
                    <Col sm={6} xs={12}>
                      <CheckboxFormsy
                        required
                        name="checkbox"
                        label={
                          <label>
                            ¿Aceptas haber leido el{' '}
                            <a target="_blank" href="/avisoprivacidad">
                              aviso de privacidad
                            </a>
                            ?
                          </label>
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} xs={12}>
                      <button type="submit" className="btn-big mt-3" disabled={!canSubmit}>
                        Registrarse
                      </button>
                    </Col>
                  </Row>
                  <div className="text-center mt-5">
                    {submitting ? (
                      <span>
                        <i className="fa fa-spin fa-spinner" /> Registrando usuario...
                      </span>
                    ) : null}
                    <p>
                      ¿Ya tienes un cuenta?<Go to="iniciarsesion"> Inicia sesión</Go>
                    </p>
                    {/* <CardTitle>
                    <Go to="recoverypassword">¿Olvidaste tu Contraseña?</Go>
                  </CardTitle> */}
                  </div>
                </Container>
              </Formsy>
            </div>
          </Container>
        </AAnimate>
      </Layout>
    )
  }
}
export default Register
